<template>
  <div>
    <div class="text-right">
      <CButton size="sm" color="success mr-1" variant="outline" :pressed="toggleA" @click="toggleButton('A')" v-show="administratorShow">アドミニストレータ</CButton>
      <CButton size="sm" color="info mr-1" variant="outline" :pressed="toggleR" @click="toggleButton('R')">レジストレータ</CButton>
      <CButton size="sm" color="warning mr-1" variant="outline" :pressed="toggleM" @click="toggleButton('M')">メンテナンス</CButton>
      <CButton size="sm" color="primary ml-5 mr-1" variant="outline" :pressed="toggleP" @click="toggleButton('P')">プロバイダ</CButton>
      <CButton size="sm" color="dark mr-1" variant="outline" :pressed="toggleD" @click="toggleButton('D')">医療関係者</CButton>
    </div>
    <MyBeeSearchBar @goodPaging="goodPaging" @changePage="changePage" @devSearch="devSearch"
                    :curr-page.sync="pagingParams.currPage"
                    :per-page.sync="pagingParams.perPage"
                    :per-page-list="perPageList"
                    :pages="pages"
                    :search-bar-place-holder="searchBarPlaceHolder"
                    :keyword.sync="pagingParams.keyword">
      <template #tools>

      </template>
    </MyBeeSearchBar>
    <BeeGridTable
        ref="bee"
        border
        stripe
        :showFilter="false"
        :columns="columns"
        :loading="isLoading"
        :data="rows"
        :showPager="false"
        :maxHeight="gridCardHeight"
        highlightRow
        no-data-text="データがありません。"
        no-filtered-data-text=""
        :context="context"
        :span-method="customSpan"
        :showVerticalScrollBar="false"
        :showHorizontalScrollBar="false">
      <!-- HEADER ソート可能にするために 必要-->
<!--
      {title: '氏名', key: 'userName',slot:"userName", headSlot:"userNameHead",align:"center"},
        {title: 'email', key: 'email',slot:"email", headSlot:"emailHead",align:"center"},
        {title: '医療機関/販売店名', key: 'orgName',slot:"orgName",headSlot:"orgNameHead",align:"center"},
        {title: '販売店事業所', key: 'agencyBranchName',slot: 'agencyBranchName',headSlot: 'agencyBranchNameHead',sortable: true},
        {title: '', key: 'after',slot:"after",align:"center"},
      -->
      <template slot-scope="{ column,index }" slot="userNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
      <template slot-scope="{ column,index }" slot="emailHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
      <template slot-scope="{ column,index }" slot="roleHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
      <template slot-scope="{ column,index }" slot="orgNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
      <template slot-scope="{ column,index }" slot="agencyBranchNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
      <template slot-scope="{ column,index }" slot="archiveHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
      <template slot-scope="{ column,index }" slot="createdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
      <template slot-scope="{ column,index }" slot="updatedHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>

      <!-- cell -->
      <template slot-scope="{ row,index }" slot="userName">
        <div class="text-left">{{row.userName}}</div>
      </template>

      <template slot-scope="{ row,index }" slot="email">
        <div class="text-left">{{row.email}}</div>
      </template>

      <template slot-scope="{ row,index }" slot="role">
        <div class="text-left">
          <CBadge size="sm" color="success mr-1" v-show="row.role == 'ADMINISTRATOR'">アドミニストレータ</CBadge>
          <CBadge size="sm" color="info mr-1" v-show="row.role == 'REGISTRATOR'">レジストレータ</CBadge>
          <CBadge size="sm" color="warning mr-1" v-show="row.role == 'MAINTENANCE'">メンテナンス</CBadge>
          <span v-show="row.role == 'PROVIDER'">
            <CBadge size="sm" color="primary mr-1" >プロバイダ</CBadge>
          </span>
          <span v-show="row.role == 'DOCTOR'">
            <CBadge size="sm" color="dark mr-1"  >医療関係者</CBadge>
          </span>
        </div>
      </template>

      <template slot-scope="{ row,index }" slot="orgName">
        <div class="text-left">
          {{ row.orgName }}
        </div>
      </template>

      <template slot-scope="{ row,index }" slot="agencyBranchName">
        <div class="text-left">{{row.agencyBranchName}}</div>
      </template>

      <template slot-scope="{ row,index }" slot="created">
        <div class="text-left">{{row.created}}</div>
      </template>

      <template slot-scope="{ row,index }" slot="updated">
        <div class="text-left">{{row.updated}}</div>
      </template>

      <template slot-scope="{ row,index }" slot="after">
        <div class="text-center">
          <CButton color="success py-0 mr-2" v-show="providerOnly == false" @click="updateUser(row)">編集</CButton>
          <CButton color="success py-0 mr-2" v-show="providerOnly" @click="setProvider(row)">指定</CButton>
        </div>
      </template>

      <template slot-scope="{ row,index }" slot="archive">
        <div class="float-center">
          <CButton size="sm" color="success mr-1" variant="outline" :pressed="row.archiveFlg" @click="archive(row,index)">アーカイブ</CButton>
        </div>
      </template>
    </BeeGridTable>
  </div>

</template>

<script>


import BeeMix from '@/views/aprod/js/mixin/beeUtils'
import MyBeeHeader from "@/component/parts/MyBeeHeader";
import MyBeeSearchBar from "@/component/parts/MyBeeSearchBar";


export default {
  name: 'UserList',
  components:{
    MyBeeHeader, MyBeeSearchBar
  },
  props:{
    filterAgencyId: { default: null, type: Number },
    filterAgencyBranchId: { default: null, type: Number },
    filterHospitalId:{ default: null, type: Number },
    providerOnly:{default:false,type:Boolean},
  },
  mixins: [BeeMix],
  data: () => {
    return {
      pagingParams: {keyword: '', columnFilters: {}, sort: [], currPage: 1, perPage: 10,filterAgencyId:null},
      columns: [
        {title: 'ユーザーID', key: 'id',width:80,align:"center"},
        {title: '氏名', key: 'userName',slot:"userName", headSlot:"userNameHead",align:"center"},
        {title: 'メールアドレス', key: 'email',slot:"email", headSlot:"emailHead",align:"center"},
        {title: '権限', key: 'role',slot:"role", headSlot:"roleHead",align:"center",width:145},
        {title: '医療機関/販売店', key: 'orgName',slot:"orgName",headSlot:"orgNameHead",align:"center"},
        {title: '販売店事業所', key: 'agencyBranchName',slot: 'agencyBranchName',headSlot: 'agencyBranchNameHead',sortable: true,align:"center"},
        {title: '登録日時', key: 'created',slot:'created',headSlot: 'createdHead',align:"center"},
        {title: '更新日時', key: 'updated',slot: 'updated',headSlot: 'updatedHead',align:"center"},
        {title: ' ', key: 'after',slot:"after",align:"center",width:80},
        {title: 'アーカイブ', key: 'archive',slot:"archive",align:"center",width:120},/*headSlot: "archiveHead"*/
      ],searchBarPlaceHolder:"氏名、医療機関/販売店名、販売店事業所で検索",
      // pagingParams: Object.assign(BeeMix.data().pagingParams,{sort: [{field: "month", sort: "desc"}]}),
      toggleA:false,
      toggleR:false,
      toggleM:false,
      toggleP:false,
      toggleD:false,
    }
  },
  methods: {
    toggleButton: function (type) {
      if (type == 'A') {
        this.toggleA = !this.toggleA;
      } else if (type == 'R') {
        this.toggleR = !this.toggleR;
      } else if (type == 'M') {
        this.toggleM = !this.toggleM;
      } else if (type == 'P') {
        this.toggleP = !this.toggleP;
      } else if (type == 'D') {
        this.toggleD = !this.toggleD;
      }
      this.goodPaging();
    },
    getRoles(){
      let list = [];
      if (this.toggleA) {
        list.push("ADMINISTRATOR");
      }
      if (this.toggleR) {
        list.push("REGISTRATOR");
      }
      if (this.toggleM) {
        list.push("MAINTENANCE");
      }
      if (this.toggleP) {
        list.push("PROVIDER");
      }
      if (this.toggleD) {
        list.push("DOCTOR");
      }
      return list;
    },
    archive: function (row,index) {
      if(row.role == "ADMINISTRATOR" ){
        if(!this.isAdministrator()){
          this.$swal("アドミニストレータはアドミニストレータだけがアーカイブできます。");
          return
        }
      }
      row.originalIndex = index;
      this.$swal({
        title: "アーカイブ",
        text: row.archiveFlg ? "アーカイブ解除しますか？" : "アーカイブしますか？",
        buttons: true,
        dangerMode: true,
      })
          .then((isOk) => {
            if (isOk) {
              this.axios.post(`/api/user/archive/${row.id}`).then(response => {
                this.rows[row.originalIndex] = Object.assign(this.rows[row.originalIndex],{archiveFlg:response.data.result.archiveFlg});
              }).catch(error => console.log(error)
              ).finally(() => {
                this.isLoading = false;
              });
            }
          });

    },
    updateUser: function (row) {
      this.$emit("updateUser",row);
    },
    setProvider:function(row){
      this.$emit("setProvider",row);
    },
    goodPaging: function (isPageChange) {
      if (!isPageChange) this.pagingParams.currPage = 1;//ページが以外が変わったら最初から検索する
      this.isLoading = true;

      this.axios.post('/api/user/userPaging', {agencyId:this.filterAgencyId,
        agencyBranchId:this.filterAgencyBranchId,hospitalId:this.filterHospitalId,providerOnly:this.providerOnly,pagingForm:this.pagingParams,
        roles:this.getRoles()
      }, {headers: {'Content-Type': 'application/json'}}).then(response => {
        this.totalRecords = response.data.result.totalElements;
        this.rows = response.data.result.content;

        //ページ件数
        this.pages = response.data.result.totalPages;
        this.topLeft();
      }).catch(error => console.log(error)
      ).finally(() => {
        this.isLoading = false;
      });
    }
  },
  mounted() {
    this.goodPaging();
    if(!(this.isRole('ADMINISTRATOR') || this.isRegistrator())){
      this.hiddenColumn("archive");
    }
  },computed:{
    administratorShow() {
      return this.$store.state.user.role == 'ADMINISTRATOR';
    }
  }
}
</script>
<style scoped>
label {
  color: #3C4B64;
}
.usersHeader ,.usersBody{
  padding:3px 0px;
}

</style>